import React, { useEffect, useState } from "react"
import { Accordion } from "react-bootstrap"

import ApiSettings from "./AboutYourBusiness/ApiSettings/apiSettings"
import Branding from "./AboutYourBusiness/Branding/branding"
import Roles from "./AboutYourBusiness/Roles/roles"
import CustomContact from "./AboutYourBusiness/CustomContact/customContact"
import Project from "./AboutYourBusiness/Project/project"
import CustomAsset from "./AboutYourBusiness/CustomAsset/customAsset"
import Reviews from "./AboutYourBusiness/Reviews/reviews"
import axios from "axios"
import AxiosInstance from "../../services/axiosInstance"
import UserNameRow from "./ProfilePage/UniqRows/userNameRow"
import AccountForRow from "./ProfilePage/UniqRows/AccountRow/accountForRow"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { Link } from "gatsby"
import Layout from "../Layout/layout"
import Seo from "../seo"
import ProfileImageRow from "./ProfilePage/UniqRows/profileImageRow"
import UrlMappedRow from "./ProfilePage/urlMappedRow"
import BusinessLocation from "./AboutYourBusiness/BusinessLocations/businessLocations"

const Business = () => {
  const [icons, setIcons] = useState([]),
    [userNameData, setUserNameData] = useState(null),
    [companyData, setCompanyData] = useState(null),
    [accountData, setAccountData] = useState(null),
    [industryData, setIndustryData] = useState(null),
    [serviceData, setServiceData] = useState(null),
    [companyName, setCompanyName] = useState(""),
    [settingsData, setSettingsData] = useState([]),
    [urlMappedTo, setUrlMappedTo] = useState([])

  const getIcons = () => {
    const unInterceptedAxiosInstance = axios.create()
    unInterceptedAxiosInstance.get(`${process.env.GATSBY_API_URL}/icon/list`).then(response => {
      setIcons(response.data.data)
    })
  }

  const getCompanyDetails = async () => {
    AxiosInstance.get("/settings/general-setting/unique-data").then(response => {
      response.data.uniqueData.map(data => {
        if (data.type === "userName") {
          setUserNameData(data)
        }
        if (data.type === "AccountType") {
          setAccountData(data)
        }
        if (data.type === "CompanyName") {
          setCompanyData(data)
          setCompanyName(data.value)
        }
        if (data.type === "IndustryName") {
          setIndustryData(data)
        }
        if (data.type === "ServiceArea") {
          setServiceData(data)
        }
      })
    })
  }

  const handleSubmitCompany = e => {
    if (companyName === e.target.value) {
      return false
    }
    setCompanyName(e.target.value)

    let url = "/settings/general-setting/add"
    if (companyData) {
      url = `/settings/general-setting/edit/${companyData.id}`
    }

    AxiosInstance.post(url, {
      type: "CompanyName",
      value: e.target.value,
      privacy: "public",
    })
      .then(response => {
        setCompanyName(response.data.value)
        localStorage.setItem("CompanyName", e.target.value)
      })
      .catch(function (error) {})
  }

  const getSettingsData = async () => {
    AxiosInstance.get("/settings/general-setting/settings-data").then(response => {
      setSettingsData(response.data.data)
      setUrlMappedTo(
        response.data.data.filter(data => {
          if (data.type === "URLMappedTo") {
            return data
          }
        })
      )
    })
  }

  const handleRedirect = page => {
    window.location.href = `/settings/${page}`
  }

  useEffect(() => {
    getIcons()
    getCompanyDetails()
    getSettingsData()
  }, [])

  return (
    <Layout>
      <Seo title={`Settings - ${companyName}`} />
      <div id="right-section" className="h-100">
        <div className="row g-0">
          <div className="col" id="addressbook">
            <div className="row g-0">
              <div className="col-sm-9 mb-3">
                <div className="pageTitle_Editor">
                  <a href="/home" className="goBack">
                    <i className="bi bi-arrow-90deg-left"></i>
                  </a>
                  <OverlayTrigger placement="right" overlay={<Tooltip>Switch to Personal Profile</Tooltip>}>
                    <Link to="/settings" className="CD_UserType isCompany">
                      <i className="bi bi-building-fill" />
                    </Link>
                  </OverlayTrigger>
                  <input
                    type="text"
                    className={`form-control`}
                    name="companyName"
                    placeholder="Company Name"
                    defaultValue={companyName}
                    onBlur={handleSubmitCompany}
                  />
                </div>
              </div>
            </div>
            <div className="row g-0">
              <div className="contactDetailsContainer">
                <ProfileImageRow />
                <div className="overflow-hidden">
                  <UserNameRow userNameData={userNameData} />
                  <AccountForRow
                    accountData={accountData}
                    companyData={companyData}
                    industryData={industryData}
                    serviceData={serviceData}
                  />
                  {settingsData.map((settingData, index) => {
                    return (
                      <React.Fragment key={index}>
                        {settingData.type === "URLMappedTo" && <UrlMappedRow settingData={settingData} />}
                      </React.Fragment>
                    )
                  })}
                  {urlMappedTo.length === 0 && (
                    <UrlMappedRow settingData={{ privacy: "hidden", value: "" }} />
                  )}
                </div>
              </div>

              <div className="row g-0 pt-5">
                <div className="col-lg-12 generalSettings">
                  <Accordion defaultActiveKey="0">
                    <Roles />
                    <CustomContact icons={icons} />
                    <BusinessLocation />
                    <Project icons={icons} />
                    <CustomAsset icons={icons} />
                    <Reviews />
                    <Branding />
                    <ApiSettings />

                    <Accordion.Item eventKey="8">
                      <Accordion.Header onClick={() => handleRedirect("financial")}>
                        <i className="bi bi-currency-dollar me-2" /> Financial
                        <i className="bi bi-box-arrow-up-right ms-2" />
                      </Accordion.Header>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                      <Accordion.Header onClick={() => handleRedirect("communication")}>
                        <i className="bi bi-megaphone-fill me-2" /> Communication & Broadcasting
                      </Accordion.Header>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header onClick={() => handleRedirect("advanced")}>
                        <i className="bi bi-gear-fill me-2" /> Advanced
                      </Accordion.Header>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Business
